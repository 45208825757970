import React, { useCallback, Fragment } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadImg from '../../images/upload.svg';
import CloseImg from '../../images/close.svg';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const FileUpload = ({ files, setFiles, setError, fileSize, message }) => {
  const onDrop = useCallback(
    acceptedFiles => {
      setError(null);
      const fileSizeValidFiles = acceptedFiles.filter(acceptedFile =>
        fileSize ? acceptedFile.size / 1024 / 1024 < fileSize : true
      );

      if (acceptedFiles.length !== fileSizeValidFiles.length) {
        setError(`File size must be up to ${fileSize} Mb`);
      }
      acceptedFiles.forEach(element => {
        if (element.name.includes(' ')) {
          setError(`File ${element.name} contains spaces in its name. Please, rename file and upload it again.`);
        } else {
          setFiles([...files, ...fileSizeValidFiles]);
        }
      });
    },
    [fileSize, files, setError, setFiles]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const htmlParser = ReactHtmlParser;

  const removeFile = file => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    setError(null);
  };

  const uploadedFiles = files.map((file, index) => (
    <li key={index}>
      <button
        type="button"
        data-cy={`delete-uploaded-file-${index}`}
        className="btn-light btn btn-secondary delete-uploaded-file"
        onClick={removeFile(file)}
      >
        <span>
          {file.path.length <= 10 ? file.path : file.path.substring(0, 10) + '...' + file.path.split('.').pop()}
        </span>
        <img src={CloseImg} alt="close" />
      </button>
    </li>
  ));

  return (
    <Fragment>
      <div className="drag-n-drop" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="row">
          <div className="col-3">
            <img className="upload-img" src={UploadImg} alt="upload-icon" />
          </div>
          <div className="col-9">
            <p className="font-blue">{isDragActive ? <span>Drop file here ...</span> : htmlParser(message)}</p>
          </div>
        </div>
      </div>
      <div className="drag-n-drop-files">
        <ul>{uploadedFiles}</ul>
      </div>
    </Fragment>
  );
};

FileUpload.propTypes = {
  files: PropTypes.array.isRequired,
  setError: PropTypes.func.isRequired,
};

export default FileUpload;
