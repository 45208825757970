import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../contexts/AuthContext';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import NavigationTabs from './Tabs/NavigationTabs';

const PathTabs = ({ sections, className, reverse, type, showTabsOnlyForDirectCustomers, ...props }) => {
  const { user } = useContext(AuthContext);
  const hideTabsForIndirectCustomers = showTabsOnlyForDirectCustomers || false;

  return (
    <>
      {(!hideTabsForIndirectCustomers || user.isFirstTenant || user.isXelonChild) && (
        <NavigationTabs
          options={
            reverse
              ? sections
                  .filter(el => el)
                  .map(el => ({
                    path: el.path,
                    label: el.title,
                    dataCy: el.dataCy,
                  }))
                  .reverse()
              : sections
                  .filter(el => el)
                  .map(el => ({
                    path: el.path,
                    label: el.title,
                    dataCy: el.dataCy,
                  }))
          }
          className={className}
          type={type}
        />
      )}
      <Switch>
        {sections
          .filter(el => el)
          .map(section => (
            <Route key={section.path} path={props.match.url + section.path} exact={section.exact}>
              {section.component}
            </Route>
          ))}
        <Route key="Not Found Page">
          <Redirect to="/not-found" />
        </Route>
      </Switch>
    </>
  );
};
PathTabs.propTypes = {
  className: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      exact: PropTypes.bool,
      component: PropTypes.node,
      name: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

export default withRouter(PathTabs);
