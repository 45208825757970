import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import axios from '../../axiosInstance';
import { capitalize } from '../helpers/helperFunc';

const DeleteConfirmationModal = ({ entity, modalOpen, toggleModalItem, dataCy, ...props }) => {
  const [password, setPassword] = useState('');
  const [delcheck, setDelcheck] = useState(false);
  const [delerror, setDelerror] = useState(false);

  useEffect(() => {
    return () => {
      setPassword('');
      setDelcheck(false);
      setDelerror(false);
    };
  }, [modalOpen]);

  const handleInputChange = e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'password') {
      setPassword(value);
    }
    if (name === 'delcheck') {
      setDelcheck(value);
    }
  };

  const submit = e => {
    setDelcheck(false);
    e.preventDefault();
    const { fetchDevices, noUrlAction, url, history, onDeleteFinished, successRedirectUrl } = props;

    if (noUrlAction) {
      noUrlAction(password, e.currentTarget, onDeleteFinished);
      // setTimeout(() => {
      //   onDeleteFinished(false);
      // }, 2000);
      return;
    }

    axios
      .delete(url, { data: { password } })
      .then(r => {
        setDelcheck(true);
        const data = r.data;
        if (data) {
          toggleModalItem();
          if (onDeleteFinished) {
            onDeleteFinished();
          }
          if (history) {
            if (fetchDevices) {
              fetchDevices();
            }
            // devices rerouting shall be dynamic
            // so we dynamicallyy derive the path
            const indexOfDataToRemove = history.location.pathname.lastIndexOf('/');
            let newRoute = history.location.pathname.slice(0, indexOfDataToRemove);
            // the following cases are static, so we use the successRedirectUrl
            // we just need to check if it is passed as props
            if (successRedirectUrl) {
              newRoute = successRedirectUrl;
            }
            // we push to a new route only if we are on the infocard page
            // if not, there is no need to redirect
            // onDeleteFinished is present only on the list page
            if (!onDeleteFinished) {
              history.push(newRoute);
            }
            localStorage.removeItem('multiTenantFilteredOrgs');
          }
        } else {
          setDelerror(true);
        }
      })
      .catch(e => {
        setDelcheck(true);
        console.warn(e);
      });
  };

  const handleEnterKeyDown = e => {
    if (e.key === 'Enter') {
      submit(e);
    }
  };

  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader toggle={toggleModalItem}>
        Delete {props.name ? props.name : null} {capitalize(entity)}
      </ModalHeader>
      <ModalBody>
        <form>
          <div className={'form-check col-xs-8' + (delerror ? ' has-error' : '')}>
            <p>Would you like to delete this {entity}?</p>
            <Input
              type="checkbox"
              name="delcheck"
              className="form-check-input"
              id="delcheck"
              checked={delcheck}
              onChange={handleInputChange}
              data-cy={`approve-checkbox-${dataCy}`}
            />
            <label htmlFor="delcheck" className="form-check-label">
              Yes, i would like to delete this {entity}! This process is irreversible and will permanently delete all
              data!
              <br />
            </label>
            <label className="mt-4">For security reasons, please confirm this task by typing your password:</label>
            <input
              type="password"
              name="password"
              id="password"
              onKeyPress={delcheck ? e => handleEnterKeyDown(e) : null}
              onChange={handleInputChange}
              value={password}
              className="form-control"
              placeholder="Enter your Password"
              data-cy={`password-input-${dataCy}`}
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-8 pl-0">
                <Button
                  disabled={!delcheck}
                  color="danger"
                  onClick={delcheck ? submit : null}
                  data-cy={`delete-button-${dataCy}`}
                >
                  <span>Delete {capitalize(entity)}</span>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  entity: PropTypes.string,
  modalOpen: PropTypes.bool,
  toggleModalItem: PropTypes.func,
  url: PropTypes.string,
  onDeleteFinished: PropTypes.func,
};

export default withRouter(DeleteConfirmationModal);
