import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from '../../../axiosInstance';

const AddNodeModal = ({
  clusterIdentifier,
  editedPool,
  editedPoolIdentifier,
  addNodeModalIsOpen,
  fetchPoolCpResources,
  toggleModal,
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    axios
      .post(`api/user/kubernetes-talos/${clusterIdentifier}/add-node/${editedPoolIdentifier}`)
      .then(res => {
        if (res.status === 200) {
          fetchPoolCpResources();
          toggleModal();
        }
      })
      .catch(error => {
        console.log(error.message);
      });
  };

  return (
    <Modal isOpen={addNodeModalIsOpen} size="sm">
      <form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggleModal}>
          <div>Add node to pool:</div>
          <div>
            {editedPool.name} {editedPool.has_extra_storage ? <span>(with extra storage)</span> : ''}
          </div>
        </ModalHeader>
        <ModalBody>
          <p className="text-center">New node will be created with the following specs:</p>
          <p className="text-center">
            {editedPool.cpu} CPU Cores, {editedPool.memory} GB RAM, {editedPool.disk} GB Storage
          </p>
          {editedPool.has_extra_storage && (
            <p className="text-center">(with extra {editedPool.extra_storage} GB Storage)</p>
          )}
        </ModalBody>
        <ModalFooter className="d-flex flex-row bg-gray pt-3">
          <Button type="button" className="ml-auto btn-light" onClick={toggleModal}>
            Cancel
          </Button>
          <Button type="submit" color="secondary">
            Create
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddNodeModal;
