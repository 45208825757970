import React, { Fragment } from 'react';
import { Badge } from 'reactstrap';
import moment from 'moment';

import axios from '../axiosInstance';
import { handleDateObjectConversion } from './timeConversion';
import ListEntityName from '../components/UI/ListEntityName';

// header icons

export const headerIconsClassnames = [
  {
    path: '/my-organization',
    icons: 'fas fa-suitcase',
  },
  {
    path: '/all-organizations',
    icons: 'fas fa-sitemap',
  },
  {
    path: '/all-devices',
    icons: 'fas fa-server',
  },
  {
    path: '/templates-and-isos',
    icons: 'fas fa-file-invoice',
  },
  {
    path: '/networking',
    icons: 'fas fa-vector-square',
  },
  {
    path: '/kubernetes',
    icons: 'fas fa-dharmachakra',
  },
  {
    path: '/databases',
    icons: 'fas fa-database',
  },
  {
    path: '/persistent-storage',
    icons: 'fas fa-compact-disc',
  },
  {
    path: '/billing-details',
    icons: 'fas fa-credit-card',
  },
  {
    path: '/usage-overview',
    icons: 'fas fa-chart-pie',
  },
  {
    path: '/usage-reports',
    icons: 'fas fa-chart-pie',
  },
  {
    path: '/operational-area',
    icons: 'fas fa-wrench',
  },
  {
    path: '/sales-dashboard',
    icons: 'fas fa-chart-line',
  },
  {
    path: '/accounting',
    icons: 'far fa-address-book',
  },
  {
    path: '/announcements',
    icons: 'fas fa-comment-alt',
  },
];

// identifiers
// @deprecated use boolean useContext(AuthContext).user.isFirstTenant instead
export const tenantIdentifier0 = '2sn54783n';

// Firewall constants

export const transportProtocolsMeta = ['UDP', 'TCP', 'ICMP'];

export const protocolsMeta = [
  { name: 'http', protocol: 'tcp', port: 80 },
  { name: 'https', protocol: 'tcp', port: 443 },
  { name: 'ssh', protocol: 'tcp', port: 22 },
  { name: 'MySQL', protocol: 'tcp', port: 3306 },
  { name: 'Custom', protocol: 'tcp', port: undefined },
];

// Device constants

export const deviceDescriptionMaxLenght = 512;

// Load Balancer constant

export const lbProtocolsMeta = [
  { name: 'http', port: 80 },
  { name: 'https', port: 443 },
  { name: 'ssh', port: 22 },
  { name: 'MySQL', port: 3306 },
  { name: 'DNS', port: 53 },
  { name: 'Custom', port: undefined },
];

// third party sign-in

export const thirdPartyReqData = {
  client_id_git: process.env.REACT_APP_GITHUB_CLIENT_ID,
  client_id_google: process.env.REACT_APP_GOOGLE_CLIENT_ID,
};

// job titles

export const jobTitles = [
  { label: 'Not selected', value: '' },
  { label: 'CEO', value: 'ceo' },
  { label: 'CTO', value: 'cto' },
  { label: 'CISO / Compliance', value: 'ciso_compliance' },
  { label: 'SysAdmin / DevOps', value: 'sysadmin_devops' },
  { label: 'Developer', value: 'developer' },
  { label: 'Finance', value: 'finance' },
  { label: 'Other', value: 'other' },
];

// typesr of OS

export const OSs = [
  {
    value: 'templates_linux',
    icon: 'fab fa-linux',
    name: 'Linux',
  },
  {
    value: 'templates_windows',
    icon: 'fab fa-windows',
    name: 'Windows',
  },
  {
    value: 'templates_firewalls',
    icon: 'fas fa-shield-alt',
    name: 'Firewall',
  },
  {
    value: 'templates_custom',
    icon: 'fas fa-cogs',
    name: 'Custom',
  },
  {
    value: 'custom_isos',
    icon: 'fas fa-cube',
    name: "Custom ISO's",
  },
];

// fetchNodes function for fw, lb and waf

export const fetchNodes = async (clusterId, isCloudInit) => {
  const url = isCloudInit
    ? `/api/user/kubernetes/cluster/${clusterId}/nodes`
    : `/api/user/kubernetes/cluster/${clusterId}`;
  const nodePools = await axios
    .get(url)
    .then(res => {
      if (res && res.data) {
        if (isCloudInit) {
          return res.data;
        } else return res.data.nodePools;
      }
    })
    .catch(e => {
      console.warn(e.message);
      return [];
    });
  const nodes = isCloudInit
    ? nodePools.map(el => ({ ...el, label: el.hostname }))
    : nodePools.reduce((accum, el) => {
        return [
          ...accum,
          ...el.nodes.reduce(
            (acc, node) => [
              ...acc,
              {
                id: node.id,
                hostname: node.hostname,
                label: node.hostname,
                ip: node.ip,
              },
            ],
            []
          ),
        ];
      }, []);
  return nodes;
};

// const for databases
export const STORAGE_MIN = 5;
export const STORAGE_MAX = 500;

// lists columns objects

export const hvTypeColumn = {
  dataField: 'hv_system.display_short_name',
  text: '',
  headerStyle: {
    width: '100px',
  },
  style: { width: '100px' },
  formatter: (c, row) => {
    const name = c ? c.charAt(0).toUpperCase() + c.slice(1) : '';

    return (
      <>
        {Array.isArray(row.hv_systems) &&
          row.hv_systems.map(hvSystem => (
            <Badge className={`hv-type-badge hv-type-badge-${c} mb-1`} key={hvSystem.id}>
              {hvSystem.display_short_name}
            </Badge>
          ))}
        <Fragment>
          <Badge className={`hv-type-badge hv-type-badge-${c}`}>{name}</Badge>
          {row?.vdc_service_type === 9 && <Badge className="hv-type-badge hv-type-badge-replica">Replica</Badge>}
          {row?.hasReplication && <Badge className="hv-type-badge hv-type-badge-replica">MC Replica</Badge>}
          {!!row?.is_free && <Badge className="hv-type-badge hv-type-badge-free">Free</Badge>}
        </Fragment>
      </>
    );
  },
};

export const healthColumn = {
  dataField: 'health',
  text: 'Health',
  formatter: c =>
    c === 'green' ? <i className="far fa-check-circle on" /> : <div className="loading-circle rotating" />,
};

export const ownerColumn = {
  dataField: 'tenant.tenantname',
  text: 'Owner',
  align: 'left',
  headerAlign: 'left',
  style: {
    minWidth: '100px',
  },
  formatter: c => <div>{c || <span className="font-gray">no info</span>}</div>,
};

export const createdAtColumn = (convert, dataField = 'created_at', text = 'Created', sort = true, format = null) => ({
  dataField,
  text,
  sort,
  formatter: c => {
    if (!format) {
      format = { showHours: true, showSeconds: false, format: false, isTimestamp: false };
    }
    return convert && c ? handleDateObjectConversion(c, format) : c;
  },
  sortCaret: order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  },
});

export const simpleNameColumn = {
  dataField: 'name',
  text: 'Name',
  formatter: c => <ListEntityName entityName={c} />,
};

export const ipAddressColumn = {
  dataField: 'ip',
  text: 'Address',
  formatter: c => <ListEntityName entityName={c} />,
};

export const wafIdColumn = {
  dataField: 'local_id',
  text: 'WAF ID',
  formatter: c => <ListEntityName entityName={c} />,
};

export const localIdColumn = {
  dataField: 'local_id',
  sort: true,
};

export const accCreatedAtColumn = {
  dataField: 'createdAt',
  text: 'Invoice date',
  align: 'left',
  style: { minWidth: '180px' },
  sort: true,
  sortCaret: order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  },
  formatter: c => <div>{handleDateObjectConversion(c)}</div>,
};

export const accTenantNameColumn = {
  dataField: 'tenantName',
  text: 'Organization',
  align: 'left',
  style: { minWidth: '180px' },
  sort: true,
  sortCaret: order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  },
};

export const accOverDueDateColumn = {
  dataField: 'overdue',
  text: 'Overdue',
  align: 'left',
  style: { minWidth: '150px' },
  sort: false,
  formatter: c => {
    let overdue = <span>{c}</span>;
    if (c < 5) {
      overdue = <span className="text-success">{c} days</span>;
    } else if (c <= 20) {
      overdue = <span className="text-warning">{c} days</span>;
    } else if (c >= 20 && c < 33) {
      overdue = <span className="text-orange">{c} days</span>;
    } else if (c >= 33) {
      overdue = <span className="text-danger">{c} days</span>;
    }
    return overdue;
  },
};

export const accDueDateColumn = {
  dataField: 'dueDate',
  text: 'Due date',
  align: 'left',
  style: { minWidth: '180px' },
  sort: true,
  sortCaret: order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  },
  formatter: c => (
    <div>
      {handleDateObjectConversion(c, {
        showHours: false,
      })}
    </div>
  ),
};

export const accTotalColumn = {
  dataField: 'total',
  text: 'Total',
  align: 'left',
  style: { minWidth: '180px' },
  formatter: c => <div>{c} CHF</div>,
};

// Attached links

export const clusterAttachedLinks = [
  {
    to: 'https://www.xelon.ch/knowledge-base/setup-kubernetes-cluster',
    label: 'Create a Kubernetes cluster',
  },
  {
    to: 'https://docs.xelon.ch/docs/hq-firewall-service',
    label: 'Set up a Firewall',
  },
  {
    to: 'https://www.xelon.ch/knowledge-base/deploy-laravel-app-using-kubernetes/',
    label: 'How to deploy a Laravel app using Kubernetes',
  },
];

export const lbAttachedLinks = [
  {
    to: 'https://www.xelon.ch/docs/load-balancer-service',
    label: 'Load Balancing in Xelon HQ',
  },
  {
    to: 'https://www.xelon.ch/knowledge-base/setup-kubernetes-cluster/#5-toc-title',
    label: 'Load Balancing explanation',
  },
  {
    to: 'https://www.xelon.ch/docs/load-balancer-service',
    label: 'How to configure a Load Balancer',
  },
];

export const fwAttachedLinks = [
  {
    to: 'https://www.xelon.ch/docs/firewall-overview',
    label: 'General Information: Firewalls in Xelon HQ',
  },
  {
    to: 'https://www.xelon.ch/docs/hq-firewall-service',
    label: 'How To Create a Firewall Service',
  },
  {
    to: 'https://www.xelon.ch/docs/firewall-overview',
    label: 'How to set up a custom Firewall',
  },
  {
    to: 'https://www.youtube.com/watch?v=BkOKLmQqoYo&feature=youtu.be',
    type: 'video',
    label: 'Firewall Service in Xelon HQ',
  },
];

// constants for billing plan creation
export const informationMessagesBillPlanCreation = [
  {
    id: 'computer-resources',
    infoMessage: 'Computer resources are calculated on an hourly basis.',
  },
  {
    id: 'network-resources',
    infoMessage:
      'Networks are calculated on a hourly basis. You’ll be charged for the networks you include for your customers.',
  },
  {
    id: 'licenses',
    infoMessage: 'Licenses are calculated on an hourly basis regardless of VM status.',
  },
  {
    id: 'network-upgrades',
    infoMessage:
      'Network upgrades are calculated on a hourly basis and summed with the base network price. Charged only in the Public Cloud.',
  },
  {
    id: 'setup-fees',
    infoMessage: 'Setup fee for creating resource.',
  },
  {
    id: 'month-fees',
    infoMessage: 'Monthly fees are charged on monthly basis.',
  },
];

// for invoices common methods

export const searchValues = {
  id: 'invoices.id',
  createdAt: 'invoices.created_at',
  dueDate: 'invoices.due_date',
  tenantName: 'tenants.tenantname',
  'invoicePayment.paymentDate': 'invoice_payments.payment_date',
};

export const DEFAULT_SIZE_PER_PAGE = 5;

export const handleRecreateDraft = (id, fetchInvoices, handleSetAmountOfInvoices) => {
  axios
    .post(`api/user/invoice/update-state`, {
      invoiceId: id,
      state: 'draft',
    })
    .then(() => {
      fetchInvoices(DEFAULT_SIZE_PER_PAGE, 1);
      handleSetAmountOfInvoices();
    });
};

// for setting the month start and end

const startOfMonth = moment().startOf('month');
const endOfMonth = moment().endOf('month');
export const initDates = [new Date(startOfMonth), new Date(endOfMonth)];

// IPv4 regex
export const IPv4Pattern = '^((\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\.){3}(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])$';

export const JOB_STATUS_PROCESSING = 0;
export const JOB_STATUS_RUNNING = 1;
export const JOB_STATUS_SKIP = 2;
export const JOB_STATUS_SUCCESS = 3;
export const JOB_STATUS_FAIL = 4;

export const ANNOUNCEMENT_STATUS_DRAFT = 'draft';
export const ANNOUNCEMENT_STATUS_PUBLISHED = 'published';
export const ANNOUNCEMENT_STATUS_UNPUBLISHED = 'unpublished';
export const ANNOUNCEMENT_AUDIENCE_CURRENT = 'current';
export const ANNOUNCEMENT_AUDIENCE_SUBTENANTS = 'subtenants';
export const ANNOUNCEMENT_AUDIENCE_TENANTS = 'tenants';
export const ANNOUNCEMENT_AUDIENCE_USERS = 'users';

// Tenant types
export const TYPE_RESELLER = 1;
export const TYPE_ENDCUSTOMER = 2;

// Device Service Types
export const SERVICE_TYPE_VM_PUBLIC_CLOUD = 1;
export const SERVICE_TYPE_VM_PRIVATE_CLOUD = 2;
export const SERVICE_TYPE_K8s_NODE = 3;
export const SERVICE_TYPE_RANCHER = 4;
export const SERVICE_TYPE_LB_SERVICE = 5;
export const SERVICE_TYPE_GATEWAY_SERVICE = 6;
export const SERVICE_TYPE_MANAGED_SERVICE = 7;
export const SERVICE_TYPE_FIREWALL = 8;
export const SERVICE_TYPE_VM_REPLICATED = 9;
export const SERVICE_TYPE_WAF = 10;
export const SERVICE_TYPE_K8s_MASTER_NODE = 11;
export const SERVICE_TYPE_K8s_LB = 12;
export const SERVICE_TYPE_K8s_CP_NODE = 13;
export const SERVICE_TYPE_K8s_W_NODE = 14;
