import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from '../contexts/AuthContext';

const RangedInput = props => {
  const classes = props.classes ? props.classes : '';
  const authCont = useContext(AuthContext);

  return (
    <div className={'ranged-input-group ' + classes}>
      {props.title ? (
        <label className="pb-0" htmlFor={props.name}>
          {props.title}
        </label>
      ) : null}
      <div className="ranged-input">
        <button
          data-cy={`button-1-${props.dataCy}` || 'no-datacy-set'}
          style={{
            borderTopLeftRadius: '2px',
            borderBottomLeftRadius: '2px',
            borderRight: 'none',
          }}
          type="button"
          className="btn-squared minus"
          onClick={() => props.subtracted(props.name)}
          disabled={props.min()}
        >
          -
        </button>
        <input
          data-cy={`input-${props.dataCy}` || 'no-datacy-set'}
          className={props.inputClassName}
          type="text"
          id={props.name}
          name={props.name}
          value={+props.val}
          onBlur={props.onBlur}
          onChange={props.handleInputChange}
          required={props.required}
          min="1"
          disabled={!!props.disabled}
        />
        <button
          style={{
            borderTopRightRadius: '2px',
            borderBottomRightRadius: '2px',
            borderLeft: 'none',
          }}
          data-cy={`button-2-${props.dataCy}` || 'no-datacy-set'}
          type="button"
          className="btn-squared plus"
          onClick={() => props.added(props.name)}
          disabled={props.max()}
        >
          +
        </button>
      </div>
      {props.max() == props.min() ? (
        <div className="py-2 my-1" />
      ) : props.max() ? (
        <small className="text-muted">{'Max' + (authCont.demoTenant ? ' Trial ' : ' ') + 'size'}</small>
      ) : props.min() ? (
        <small className="text-muted">{'Min' + (authCont.demoTenant ? ' Trial ' : ' ') + 'size'}</small>
      ) : null}
    </div>
  );
};

RangedInput.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleInputChange: PropTypes.func.isRequired,
  min: PropTypes.func.isRequired,
  max: PropTypes.func.isRequired,
  added: PropTypes.func.isRequired,
  subtracted: PropTypes.func.isRequired,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
};

export default RangedInput;
