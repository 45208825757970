import Errors from './errors';
import ValidatedFields from './validatedFields';

/**
 * This class helps to handle API validation errors and prevents multiple submitting.
 */
export default class Form {
  /**
   * Form constructor.
   * @param data
   */
  constructor(data) {
    this.setOriginalData(data);
    this.isPending = false;
    this.initFields(data);
    this.initErrors(data);
    this.initValidatedFields(data);
  }

  /**
   * Init form fields.
   * @param data
   */
  initFields(data) {
    Object.keys(data).forEach(field => {
      this[field] = data[field];
    });
  }

  /**
   * Init form errors.
   * @param data
   */
  initErrors(data) {
    this.errors = new Errors(Object.keys(data));
  }

  /**
   * Init form validated fields.
   * @param data
   */
  initValidatedFields(data) {
    this.validatedFields = new ValidatedFields(Object.keys(data));
  }

  /**
   * Set new original data.
   * @param data
   */
  setOriginalData(data) {
    this.originalData = data;
  }

  /**
   * Get the form data without any non-relevant fields.
   */
  data() {
    const data = {};

    Object.keys(this.originalData).forEach(property => {
      data[property] = this[property];
    });

    return data;
  }

  /**
   * Get the form field value.
   * @param field
   * @returns {null|*}
   */
  get(field) {
    return this[field];
  }

  /**
   * Transform the form data by merging with the given data object.
   * @param data
   */
  transform(data) {
    const transformed = this.data();

    Object.keys(data).forEach(property => {
      transformed[property] = data[property];
    });

    return transformed;
  }

  /**
   * Reset form fields and errors.
   */
  reset() {
    this.initFields(this.originalData);
    this.errors.clear();
    this.validatedFields.clear();
  }

  /**
   * Clear form fields and errors.
   */
  clearResults(field) {
    this.errors.clear(field);
    this.validatedFields.clear(field);
  }

  /**
   * Set errors.
   * @param errors
   * @param onlyOriginalFields
   */
  setErrors(errors, onlyOriginalFields = false) {
    this.errors.assign(errors, onlyOriginalFields);
  }

  /**
   * Set validated fields.
   * @param errorFields
   */
  setValidatedFields(errorFields) {
    this.validatedFields.setValidatedFields(errorFields);
  }

  /**
   * Submit form using promised callback.
   * @param callback
   * @param onlyOriginalFields
   * @returns {Promise<*>}
   */
  async submitUsing(callback, onlyOriginalFields = false) {
    if (this.isPending) {
      throw new Error('Request is pending...');
    }

    if (this.errors.any()) {
      throw new Error('Validation errors are not resolved.');
    }

    this.isPending = true;

    try {
      await callback(this.data());
    } catch (e) {
      console.warn('There is error', e);
      if (this.isValidationError(e)) {
        this.setErrors(e.response.data.errors, onlyOriginalFields);
        this.setValidatedFields(Object.keys(e.response.data.errors));
      }
      throw e;
    } finally {
      this.isPending = false;
    }
  }

  isValidationError(error) {
    return (
      error && error.response && error.response.data && error.response.data.errors && error.response.status === 422
    );
  }
}
