import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ price, visible, balance }) => {
  return visible ? (
    <div className="onboarding-footer-container">
      <div className="onboarding-footer-summary">
        <div className="row ml-0 mr-0">
          <div className="col-2 offset-2">
            <p style={{ marginTop: '2rem' }} className="font-size-lg font-weight-bold">
              Summary
            </p>
          </div>
          {price ? (
            <div className="col-3 offset-5">
              <p className="font-weight-bold mt-3">Total: {price} CHF daily</p>
              <p>Your balance: {balance} Credits</p>
            </div>
          ) : null}
        </div>
      </div>
      <button type="submit" data-cy="onboarding-submit" className="onboarding-footer-submit">
        Click to proceed
        <i className="fas fa-arrow-right ml-3" />
      </button>
    </div>
  ) : null;
};

Footer.propTypes = {
  price: PropTypes.number,
  visible: PropTypes.bool,
  balance: PropTypes.number,
};

export default Footer;
