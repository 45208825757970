import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import axios from '../../../axiosInstance';
import FeatureModalBody from './FeatureModalBody';
import BugModalBody from './BugModalBody';

const ReportModal = ({ modalOpen, type, toggleModal }) => {
  const [summary, setSummary] = useState('');
  const [impact, setImpact] = useState('');
  const [description, setDescription] = useState('');
  const [expectedBehavior, setExpectedBehavior] = useState('');
  const [actualBehavior, setActualBehavior] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [category, setCategory] = useState('');
  const [severity, setSeverity] = useState('');
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  // added effect to return data to initial state after component is removed (unmounted)
  useEffect(() => {
    return () => {
      setSummary('');
      setDescription('');
      setFiles([]);
      setError(null);
    };
  }, [modalOpen]);

  const submit = () => {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('uploadedFiles[]', file);
    });
    formData.append('summary', summary);
    formData.append('description', description);
    formData.append('category', category);
    formData.append('severity', severity);
    formData.append('impact', impact);
    formData.append('expectedBehavior', expectedBehavior);
    formData.append('actualBehavior', actualBehavior);
    formData.append('additionalInfo', additionalInfo);
    formData.append('issueType', type);
    formData.append('url', window.location.href);
    formData.append('userAgent', window.navigator.userAgent);

    if (!isPending) {
      setIsPending(true);

      axios
        .post('/api/user/userReport', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          if (res) {
            toggleModal();
            setFiles([]);
            setSummary('');
            setDescription('');
            setError(null);
          }
          setIsPending(false);
        })
        .catch(() => setIsPending(false));
    }
  };

  const removeAll = () => {
    setFiles([]);
  };

  let headerText, submitText;

  if (type === 'Bug') {
    headerText = 'Report a bug';
    submitText = 'Report';
  } else if (type === 'Improvement') {
    headerText = 'Request a feature';
    submitText = 'Request';
  }

  return (
    <Modal centered isOpen={modalOpen} className={`${type === 'Bug' ? 'modal-lg' : ''}`}>
      <ModalHeader toggle={toggleModal}>{headerText}</ModalHeader>
      <ModalBody>
        {type === 'Bug' ? (
          <BugModalBody
            summary={summary}
            setSummary={setSummary}
            impact={impact}
            setImpact={setImpact}
            setCategory={setCategory}
            description={description}
            setDescription={setDescription}
            expectedBehavior={expectedBehavior}
            setExpectedBehavior={setExpectedBehavior}
            actualBehavior={actualBehavior}
            setActualBehavior={setActualBehavior}
            additionalInfo={additionalInfo}
            setAdditionalInfo={setAdditionalInfo}
            files={files}
            setFiles={setFiles}
            setError={setError}
          />
        ) : (
          <FeatureModalBody
            summary={summary}
            setSummary={setSummary}
            setSeverity={setSeverity}
            description={description}
            setDescription={setDescription}
          />
        )}
      </ModalBody>
      <ModalFooter>
        {files.length > 0 && (
          <button className="btn btn-pink" onClick={removeAll} data-cy="remove-all-uploaded-files">
            Remove all files
          </button>
        )}

        <p style={{ color: error ? 'red' : 'rgb(51,51,51)' }} className="mt-2">
          {error ? error : ''}
        </p>
        <Button color="primary" data-cy="report-bug-submit" onClick={submit} className="ml-auto" disabled={isPending}>
          {submitText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ReportModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default ReportModal;
