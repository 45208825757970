/* eslint-disable */
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { ViewContext } from '../contexts/ViewContext';
import { AuthContext } from '../contexts/AuthContext';

const AnalyticsHoc = ({ children }) => {
  const analyticsEnabled =
    process.env.REACT_APP_ENABLE_ANALYTICS &&
    process.env.REACT_APP_ENABLE_ANALYTICS === 'true'
      ? true
      : false;
  const authInfo = useContext(AuthContext);
  const screenSize = useContext(ViewContext);
  const location = useLocation();
  useEffect(() => {
    if (analyticsEnabled) {
      const head = document.getElementsByTagName('head')[0];
      const js = document.createElement('script');

      // userpilot settings
      window.userpilotSettings = {
        token: process.env.REACT_APP_USERPILOT_TOKEN,
      };
      js.src = 'https://js.userpilot.io/sdk/latest.js';
      // userpilot settings

      head.appendChild(js);
    }
  }, []);
  useEffect(() => {
    analyticsEnabled &&
      window.userpilot &&
      window.userpilot.identify(authInfo.user.id, {
        name: authInfo.user.full_name,
        email: authInfo.user.email,
        created_at: moment(authInfo.user.created_at).format(),
        company: {
          parent_id:
            authInfo.user.tenant && authInfo.user.tenant.parent_identifier
              ? authInfo.user.tenant.parent_identifier
              : '2sn54783n',
          id: authInfo.user.tenant && authInfo.user.tenant.tenant_identifier,
          name: authInfo.user.tenant && authInfo.user.tenant.tenantname,
          created_at:
            authInfo.user.tenant &&
            moment(authInfo.user.tenant.created_at).format(),
        },
        job_title: authInfo.user.job_title,
        'Tenant Creation Date':
          authInfo.user.tenant &&
          moment(authInfo.user.tenant.created_at).format(),
        'User Registration Date': moment(authInfo.user.created_at).format(),
        'Customer Persona':
          authInfo.user.tenant && authInfo.user.tenant.customer_type,
        country: authInfo.user.tenant && authInfo.user.tenant.country,
        'Has WAN': authInfo.user.have_network,
        'Has Payment': authInfo.user.have_payment,
        'Is desktop': screenSize === 'lg' || screenSize === 'xl' ? 1 : 0,
      });
  }, [location.pathname, authInfo.user]);

  return children;
};

export default AnalyticsHoc;
